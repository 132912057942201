import { useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { useEffect } from "react";

export const initSentryClient = () => {
  if (window.location.hostname === "localhost") {
    return;
  }
  Sentry.init({
    dsn: "https://9b59a85e9346cdeb61962cf2e033322e@o4505562198638592.ingest.us.sentry.io/4507912871280640",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration(),
    ],
  });
};
